const zeroPad = (num: number, places: number | 1): string => {
  if (!num) return "";
  return String(num).padStart(places, "0");
};

const mcConsoleLog = (
  message: string | "Cammarata Console: ",
  args?: any | null
): void => {
  if (process.env.NODE_ENV === "development") console.log(message, args);
};

const prezzoFormatted = (num) => {
  return new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR"
  }).format(num ?? 0);
};

export { zeroPad, mcConsoleLog, prezzoFormatted };
