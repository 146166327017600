import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { UserLevel } from "@/core/helpers/constants";
import { mcConsoleLog } from "@/core/helpers/utility";
// import JwtService  ce from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      level: UserLevel.CLIENTE,
      middleware: "auth"
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          level: UserLevel.CLIENTE,
          pageTitle: "Dashboard",
          breadcrumbs: []
        }
      },
      {
        path: "/utenti",
        redirect: "/utenti/grid",
        component: () => import("@/views/utenti/UtentiView.vue"),
        meta: {
          breadcrumbs: []
        },
        children: [
          {
            path: "grid",
            name: "utenti-grid",
            component: () => import("@/views/utenti/UtentiGrid.vue"),
            meta: {
              level: UserLevel.ADMIN,
              pageTitle: "Lista Utenti"
            }
          },
          {
            path: "header/:id",
            component: () => import("@/views/utenti/UtentiHeader.vue"),
            beforeEnter: (to, from, next) => {
              mcConsoleLog("beforeEnter to  ", to.path);
              const paths = to.path.split("/");
              if (
                paths[paths.length - 1] != "overview" &&
                paths[paths.length - 1] != "settings"
              ) {
                mcConsoleLog("SECONDO CASO to  ", paths[paths.length - 2]);
                next({ name: "utenti-grid" });
              } else if (
                paths[paths.length - 2] == "header" &&
                (paths[paths.length - 1] == "overview" ||
                  paths[paths.length - 1] == "settings")
              ) {
                mcConsoleLog("SECONDO CASO to  ", paths[paths.length - 2]);
                next({ name: "utenti-grid" });
              }
              next();
            },
            props: true,
            children: [
              {
                path: "overview",
                name: "utenti-overview",
                component: () => import("@/views/utenti/UtentiOverview.vue"),
                meta: {
                  level: UserLevel.CLIENTE,
                  pageTitle: "Profilo Utente",
                  breadcrumbs: [
                    {
                      label: "Utenti",
                      link: "/utenti/grid"
                    }
                  ]
                }
              },
              {
                path: "settings",
                name: "utenti-settings",
                component: () => import("@/views/utenti/UtentiSettings.vue"),
                meta: {
                  level: UserLevel.CLIENTE,
                  pageTitle: "Impostazioni",
                  breadcrumbs: [
                    {
                      label: "Utenti",
                      link: "/utenti/grid"
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      {
        path: "/distributori",
        redirect: "/distributori/grid",
        component: () => import("@/views/distributori/DistributoriView.vue"),
        meta: {
          breadcrumbs: []
        },
        children: [
          {
            path: "grid",
            name: "distributori-grid",
            component: () =>
              import("@/views/distributori/DistributoriGrid.vue"),
            meta: {
              level: UserLevel.TECNICO,
              pageTitle: "Lista Distributori"
            }
          },
          {
            path: "header/:id",
            name: "distributori-overview",
            component: () =>
              import("@/views/distributori/DistributoriHeader.vue"),
            props: true,
            meta: {
              level: UserLevel.TECNICO,
              pageTitle: "Dettaqgli Distributore",
              breadcrumbs: [
                {
                  label: "Distributori",
                  link: "/distributori/grid"
                }
              ]
            }
          }
        ]
      },
      {
        path: "/prodotti",
        redirect: "/prodotti/gestione-prodotti/grid",
        meta: {
          breadcrumbs: []
        },
        children: [
          {
            path: "gestione-prodotti",
            component: () =>
              import("@/views/prodotti/gestione-prodotti/ProdottiView.vue"),
            meta: {
              breadcrumbs: []
            },
            children: [
              {
                path: "grid",
                name: "prodotti-grid",
                component: () =>
                  import("@/views/prodotti/gestione-prodotti/ProdottiGrid.vue"),
                meta: {
                  level: UserLevel.TECNICO,
                  pageTitle: "Lista Prodotti"
                }
              },
              {
                path: "header/:id",
                component: () =>
                  import(
                    "@/views/prodotti/gestione-prodotti/ProdottiHeader.vue"
                  ),
                props: true,
                meta: {
                  level: UserLevel.TECNICO
                },

                children: [
                  {
                    path: "overview",
                    name: "prodotti-overview",
                    component: () =>
                      import(
                        "@/views/prodotti/gestione-prodotti/ProdottiOverview.vue"
                      ),
                    meta: {
                      level: UserLevel.TECNICO,
                      pageTitle: "Profilo Prodotto",
                      breadcrumbs: [
                        {
                          label: "Prodotti",
                          link: "/prodotti/gestione-prodotti/grid"
                        }
                      ]
                    }
                  },
                  {
                    path: "settings",
                    name: "prodotti-settings",
                    component: () =>
                      import(
                        "@/views/prodotti/gestione-prodotti/ProdottiSettings.vue"
                      ),
                    meta: {
                      level: UserLevel.ADMIN,
                      pageTitle: "Impostazioni",
                      breadcrumbs: [
                        {
                          label: "Prodotti",
                          link: "/prodotti/gestione-prodotti/grid"
                        }
                      ]
                    }
                  }
                ]
              }
            ]
          },
          {
            path: "categorie",
            redirect: "/prodotti/categorie/grid",

            component: () =>
              import("@/views/prodotti/categorie/CategorieView.vue"),
            meta: {
              level: UserLevel.TECNICO,

              breadcrumbs: []
            },
            children: [
              {
                path: "grid",
                name: "categorie-grid",
                component: () =>
                  import("@/views/prodotti/categorie/CategorieGrid.vue"),
                meta: {
                  level: UserLevel.TECNICO,
                  pageTitle: "Lista Categorie"
                }
              },
              {
                path: "header/:id",
                component: () =>
                  import("@/views/prodotti/categorie/CategorieHeader.vue"),
                props: true,
                children: [
                  {
                    path: "overview",
                    name: "categorie-overview",
                    component: () =>
                      import(
                        "@/views/prodotti/categorie/CategorieOverview.vue"
                      ),
                    meta: {
                      pageTitle: "Categoria",
                      breadcrumbs: [
                        {
                          label: "Categorie",
                          link: "/prodotti/categorie/grid"
                        }
                      ]
                    }
                  },
                  {
                    path: "settings",
                    name: "categorie-settings",
                    component: () =>
                      import(
                        "@/views/prodotti/categorie/CategorieSettings.vue"
                      ),
                    meta: {
                      level: UserLevel.TECNICO,
                      pageTitle: "Impostazioni",
                      breadcrumbs: [
                        {
                          label: "Categorie",
                          link: "/prodotti/categorie/grid"
                        }
                      ]
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: "/shop",
        meta: {
          breadcrumbs: []
        },
        children: [
          {
            path: "multistepdistributore",
            component: () =>
              import("@/views/shopDistributori/MultiStepDistributore.vue"),
            meta: {
              pageTitle: "Shop",
              breadcrumbs: []
            }
          }
        ]
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/LayoutBuilder.vue"),
        meta: {
          pageTitle: "Layout Builder",
          breadcrumbs: ["Layout"]
        }
      }
    ]
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In"
        }
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up"
        }
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset"
        }
      }
    ]
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404"
        }
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500"
        }
      }
    ]
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404"
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Verifica Token con chiamata separata
  store.dispatch(Actions.VERIFY_AUTH);
  if (
    to.name !== "sign-in" &&
    to.name !== "sign-up" &&
    !store.getters.isUserAuthenticated
  )
    next({ name: "sign-in" });
  else next();

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  });
});

router.beforeResolve((to, from, next) => {
  if (to.name == "sign-in" || to.name == "sign-up") next();
  else if (to.meta.level && to.meta.level > store.getters.getUserLevel) {
    next({ name: "dashboard" });
    return false;
  }
  next();
});

export default router;
